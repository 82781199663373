export enum EntityViews {
  factsheet = 'factsheet',
  relations = 'relations',
  hierarchy = 'hierarchy',
}
export type EntityView = keyof typeof EntityViews;

export enum SearchViews {
  list = 'list',
  mapTimeline = 'mapTimeline',
  map = 'map',
  timeline = 'timeline',
}
export type SearchView = keyof typeof SearchViews;

export enum StatefulUI {
  factsheetDevDataON = 'factsheetDevDataON',
  factsheetLabelsON = 'factsheetLabelsON',
  filtersPanelOn = 'filtersPanelON',
  graphInfopanelON = 'graphInfopanelON',
  hierarchyPolyTooltip = 'hierarchyPolyTooltip',
  loadingInfopanel = 'loadingInfopanel',
  mapRelations = 'mapRelations',
  timelineDropdownOpen = 'timelineDropdownOpen',
  timelineRangeErrorON = 'timelineRangeErrorON',
  timelineAccordionOpen = 'timelineAccordionOpen',
  activeOverlay = 'activeOverlay',
}

export type PolyTooltipPosition = { top: number; left: number } | null;
export type PolyTooltipState = {
  id: string | null;
  position: PolyTooltipPosition;
};

export interface GeneralUIState {
  readonly view: {
    entity: EntityView;
    search: SearchView;
  };
  readonly pageTitle: string;
  readonly [StatefulUI.activeOverlay]: string | null;
  readonly [StatefulUI.factsheetDevDataON]: boolean;
  readonly [StatefulUI.factsheetLabelsON]: boolean;
  readonly [StatefulUI.graphInfopanelON]: boolean;
  readonly [StatefulUI.hierarchyPolyTooltip]: PolyTooltipState;
  readonly [StatefulUI.loadingInfopanel]: boolean;
  readonly [StatefulUI.filtersPanelOn]: boolean;
  readonly [StatefulUI.mapRelations]: Record<string, boolean>;
  readonly [StatefulUI.timelineDropdownOpen]: boolean;
  readonly [StatefulUI.timelineRangeErrorON]: boolean;
  readonly [StatefulUI.timelineAccordionOpen]: boolean;
}
