import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { commonMessages } from '../../../locales/common/messages';
import { toDate } from '../../../utils/dateFormat';
import { selectAppVersion, selectDevDataEnabled } from '../../features/Config';
import { actions as UIActions, StatefulUI } from '../../features/GeneralUI';
import {
  selectServicesVersions,
  selectAreVersionsLoaded,
  selectIsLoadingVersions,
  selectRequestError,
  useVersionsSlice,
  ServicesVersionsJson,
} from '../../features/Versions';
import { RouteUtils } from '../../routes';
import { BlackButton } from '../Button';
import { LI } from '../HTML';
import { FooterNavLink, FooterNavNoLink } from '../Link';
import { VisuallyHidden } from '../VisuallyHidden';
import { messages } from './messages';
import { Footer, FooterGrid, List, Title, Versions } from './style';

export const footerLinks: string[] = [
  'contact',
  'impressum',
  'datenschutz',
  'accessibility',
  'aboutGndNetwork',
];

export function PageFooter() {
  const { actions } = useVersionsSlice();
  const dispatch = useDispatch();
  const { t } = useTranslation(['translation', 'footer']);

  const appVersion: string = useSelector(selectAppVersion);
  const isLoading: any = useSelector(selectIsLoadingVersions);
  const isLoaded: any = useSelector(selectAreVersionsLoaded);
  const servicesVersions: ServicesVersionsJson = useSelector(
    selectServicesVersions,
  );
  const requestError = useSelector(selectRequestError);
  const devDataEnabled: boolean = useSelector(selectDevDataEnabled);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isLoading && !isLoaded && !requestError) {
      dispatch(actions.loadServiceVersions());
    }
  }, [actions, dispatch, isLoaded, isLoading, requestError]);

  const toggleDevData = () => {
    dispatch(UIActions.toggle({ name: StatefulUI.factsheetDevDataON }));
  };

  const datasetDate = servicesVersions?.dumpDate
    ? t(commonMessages.formattedIntlDate, {
        date: toDate(servicesVersions.dumpDate),
      })
    : '';
  return (
    <Footer id="page-footer">
      <FooterGrid>
        <Versions>
          <VisuallyHidden as="h1">{t(messages.footerInfoTitle)}</VisuallyHidden>
          <div>
            <Title>{t(`translation:${messages.title}`)}</Title>
            <span> {t(`translation:${messages.dataset}`)}</span>
            {devDataEnabled && datasetDate && (
              <span>
                {' '}
                {t(`translation:${messages.date}`, {
                  date: datasetDate,
                })}{' '}
              </span>
            )}
          </div>
          {devDataEnabled && (
            <>
              <div>
                {t(`translation:${messages.portalRelease}`, {
                  version: appVersion,
                })}
              </div>
              {servicesVersions?.searchRelease && (
                <div>
                  {t(`translation:${messages.searchServiceRelease}`, {
                    version: servicesVersions.searchRelease,
                  })}
                </div>
              )}
              {servicesVersions?.indexerRelease && (
                <div>
                  {t(`translation:${messages.indexerRelease}`, {
                    version: servicesVersions.indexerRelease,
                  })}
                </div>
              )}
            </>
          )}
        </Versions>

        <List>
          {footerLinks.map((key: string) => {
            const text: string = `footer.link.${key}.text`;
            const url: string = `footer.link.${key}.url`;
            const isActive: boolean =
              window.location.pathname === t(`footer:${url}`);

            return (
              <LI key={key}>
                {isActive ? (
                  <FooterNavNoLink>{t(`footer:${text}`)}</FooterNavNoLink>
                ) : (
                  <FooterNavLink
                    target={key === 'accessibility' ? '_self' : '_blank'}
                    rel="noreferrer"
                    href={t(`footer:${url}`)}
                  >
                    {t(`footer:${text}`)}
                  </FooterNavLink>
                )}
              </LI>
            );
          })}
        </List>
        {devDataEnabled && RouteUtils.isEntityOrSearchPage(pathname) && (
          <div style={{ marginTop: '1rem' }}>
            <BlackButton onClick={toggleDevData}>Toggle dev data</BlackButton>
          </div>
        )}
      </FooterGrid>
    </Footer>
  );
}
